.bio-heading {
  font-family: 'Roboto', sans-serif;
  color: white;
}

.bio-container {
  grid-gap: 50px 50px;
  background-color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.column-1 {
  margin-top: 40px;
}

.column-2 {
  margin-top: 40px;
  width: 50%;
}

.personal-img {
  width: 500px;
  height: 580px;
  object-fit: cover;
  object-position: 80% 10%;
  background-color: black;
}

@media only screen and (max-width: 600px) {
  .personal-img{
    width: 250px;
    height: 350px;
    object-fit: contain;
    object-position: 80% 10%;
    background-color: black;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .bio-information {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    color: lightgrey;
    font-size: 26px;
    font-family: 'Roboto', sans-serif;
    line-height: 2.5rem;
  }
}

@media screen and (max-width: 600px) {
  .column-2 {
    width: 70%;
  }
}
.bio-information {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  color: lightgrey;
  font-size: 22px;
  font-family: 'Roboto', sans-serif;
  line-height: 2.2rem;
}

/* @media (max-width: 400px) {
  .test-img,
  .testimonial-text {
    width: 90vw;
  }
} */

/* @media (max-width: 950px) {
  .column-2 {
    width: 100%;
    flex-direction: column;
    row-gap: 30px;
    width: 70%;
    margin: 0 auto;
    text-align: center;
  }
  .bio-information {
    text-align: center;
  }
} */
