$body-bg: #000;

$primary-color: #cf7f00;
$secondary-color: #74aa50;

$txt-color: #fff;

$small-text-size: 27px;
$heading-text-size: clamp(4.2rem, 1vw, 8rem);
$normal-text-size: clamp(1.35rem, 1vw, 7rem);

$normal-text-style: italic;
$heading-text-style: lighter;

$white: #fff;
$black: #000;

$section-padding: 7rem;

$header-mobile-height: 30rem;
$header-tablet-height: 30rem;
$header-shrink-height: 6rem;

$border-radius: 16px 0px;

$mobile-width: 600px;
$tablet-width: 1024px;

$font-family: 'Montserrat', sans-serif;
$font-family-osake: 'Osake';