.video-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5rem;
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 10px;
  background-color: transparent;
  color: #fff;
  border-radius: 2rem;
  cursor: pointer;
}

.animation-video {
  overflow: hidden;
  height: 100%;
  width: 100vw;
}

