.video-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5rem;
  max-width: 100%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 10px;
  background-color: transparent;
  color: #fff;
  border-radius: 2rem;
  cursor: pointer;
}

.animation-video {
  overflow: hidden;
  height: 100%;
  width: 100vw;
}

.video-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.5s;
}
  
.video-container:hover .video-controls {
  opacity: 1;
}
.video-container.touched .video-controls {
  opacity: 1;
}

.full-screen {
  position: absolute;
  top: 96%;
  left: 97.5%;
  color: white;
  margin-bottom: 2px;
}

@media only screen and (max-width: 600px) {
  .full-screen {
    position: absolute;
    top: 85%;
    left: 91%;
  }
  
}


/* @media only screen and (max-width: 600px) {
  .video-controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity 0.5s;
  }
}

@media only screen and (max-width: 600px) {
  .video-container:hover .video-controls {
    opacity: 1;
  }
}  */

/* .video-container {
  position: relative;
}

.video-container button {
  display: none; 
}

video:focus + .video-container button {
  display: block; 
} */