body {
  margin: 0;
  font-family: 'Roboto', sans-serif ;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto', sans-serif;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}
