.aboutpage-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.about-conatiner {
  display: flex;
  flex-direction: column;
}
.about-text {
  width: 70%;
  margin: 0 auto;
  font-size: large;
  color: white;
}

.about-main-div {
  background-color: black;
}

.first-paragraph {
  margin-top: 15px;
  line-height: 2.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

.about-heading-paragraph {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  color: white;
  padding-top: 20px;
}

.second-paragraph {
  font-family: "Roboto", sans-serif;
  margin-top: 20px;
  line-height: 2.5rem;
  font-size: 20px;
}
.third-paragraph {
  margin-top: 20px;
  line-height: 2.2rem;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}
